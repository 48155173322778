<template>
  <div class="course-edit"
       v-loading="pageLoading">
    <el-card class="box-card detailHeaderBox"
             shadow="never">
      <div class="course-detail-header content"
           :style="{ 'background-image': 'url('+defaultHeaderBg+')', 'background-repeat': 'no-repeat' , 'background-size': '100% 60%' }">
        <div class="header-left">
          <div class="img-div">
            <img :src="courseContentInfo.coverImg ? courseContentInfo.coverImg : defaultHeaderImg"
                 class="course-img" />
          </div>
          <div class="course-info">
            <div class="title">
              <div class="icon-div">{{ courseContentInfo.status == '0' ? '未发布' : courseContentInfo.status == '1' ? '已上架' : courseContentInfo.status == '2' ? '已下架' : '' }}</div>
              <span>{{ courseContentInfo.courseThemeName }}</span>
            </div>
            <div class="description">{{ courseContentInfo.description }}</div>
            <div class="course-info-bottom">
              <div class="bottom-item">
                <div class="label">老师：</div>
                <div class="text">{{ courseContentInfo.userName }}</div>
                <div class="line"></div>
              </div>
              <div class="bottom-item">
                <div class="label long">创建时间：</div>
                <div class="text long">{{ courseContentInfo.createTime }}</div>
              </div>
            </div>
          </div>
        </div>
        <div class="header-right">
          <el-tooltip class="item"
                      effect="light"
                      popper-class="customPopper"
                      content="编辑"
                      placement="bottom"
                      v-if="isUpdate">
            <img :src="detailEditIconHeader"
                 alt=""
                 @click="courseEdit"
                 v-if="isUpdate" />
          </el-tooltip>
          <el-tooltip class="item"
                      effect="light"
                      popper-class="customPopper"
                      content="发布"
                      placement="bottom"
                      v-if="courseContentInfo.status == '0' && isUpdate">
            <img :src="detailPushIconHeader"
                 alt=""
                 @click="coursePush"
                 v-if="courseContentInfo.status == '0' && isUpdate" />
          </el-tooltip>
        </div>
      </div>
    </el-card>
    <div class="content-body">
      <div class="body-left"
           ref="bodyLeft"
           :style="{ height: bodyLeftHeight }">
        <div class="tree-list"
             v-if="catalogData.length > 0"
             @mouseleave="treeLeave">
          <div class="tree-outside"
               v-for="(treeInfo, index) in catalogData"
               :key="treeInfo.id">
            <div class="tree-item">
              <div class="tree-title"
                   :ref="'tree-title-'+treeInfo.id"
                   :class="{ active: treeInfo.id == courseContentCatalogId }"
                   @click="handleTreeClick(treeInfo)"
                   @mouseenter="(e) => treeHover(e, null, null, null, null, treeInfo, index)">
                <span>{{ treeInfo.catalogueName }}</span>
                <div class="poper-div"
                     :ref="'poper-div-'+treeInfo.id"
                     v-show="treeInfo.isShowPopover">
                  <span class="poper-item"
                        @click.stop="handleCatalogItem('contentSetting',treeInfo)">内容</span>
                  <el-divider direction="vertical"></el-divider>
                  <span class="poper-item"
                        @click.stop="handleCatalogItem('targetSetting',treeInfo)">目标</span>
                  <el-divider direction="vertical"></el-divider>
                  <span class="poper-item"
                        @click.stop="handleCatalogItem('edit',treeInfo)">编辑</span>
                  <el-divider direction="vertical"></el-divider>
                  <span class="poper-item"
                        @click.stop="handleCatalogItem('add',treeInfo)">添加</span>
                  <el-divider direction="vertical"></el-divider>
                  <span class="poper-item"
                        @click.stop="handleCatalogItem('del',treeInfo)">删除</span>
                  <div class="popor-arrow"
                       style="left: 1.04167vw"></div>
                </div>
              </div>
              <div v-if="treeInfo.children"
                   class="sub-tree-list">
                <div class="tree-item"
                     v-for="(subTreeInfo,i) in treeInfo.children"
                     :key="subTreeInfo.id">
                  <div class="tree-title"
                       style="padding-left: 2.60417vw"
                       :ref="'tree-title-'+subTreeInfo.id"
                       :class="{ active: subTreeInfo.id == courseContentCatalogId }"
                       @click.stop="handleTreeClick(subTreeInfo)"
                       @mouseenter="(e) => treeHover(e, null, null, subTreeInfo, i, treeInfo, index)">
                    <span>{{ subTreeInfo.catalogueName }}</span>
                    <div class="poper-div"
                         :ref="'poper-div-'+subTreeInfo.id"
                         v-show="subTreeInfo.isShowPopover">
                      <span class="poper-item"
                            @click.stop="handleCatalogItem('contentSetting',subTreeInfo)">内容</span>
                      <el-divider direction="vertical"></el-divider>
                      <span class="poper-item"
                            @click.stop="handleCatalogItem('targetSetting',subTreeInfo)">目标</span>
                      <el-divider direction="vertical"></el-divider>
                      <span class="poper-item"
                            @click.stop="handleCatalogItem('edit',subTreeInfo)">编辑</span>
                      <el-divider direction="vertical"></el-divider>
                      <span class="poper-item"
                            @click.stop="handleCatalogItem('add',subTreeInfo)">添加</span>
                      <el-divider direction="vertical"></el-divider>
                      <span class="poper-item"
                            @click.stop="handleCatalogItem('del',subTreeInfo)">删除</span>
                      <div class="popor-arrow"
                           style="left: 2.08333vw"></div>
                    </div>
                  </div>
                  <div v-if="subTreeInfo.children"
                       class="sub-tree-list">
                    <div class="tree-item"
                         v-for="(thirdTreeInfo,j) in subTreeInfo.children"
                         :key="thirdTreeInfo.id">
                      <div class="tree-title"
                           style="padding-left: 3.125vw"
                           :ref="'tree-title-'+thirdTreeInfo.id"
                           :class="{ active: thirdTreeInfo.id == courseContentCatalogId, }"
                           @click.stop="handleTreeClick(thirdTreeInfo)"
                           @mouseenter="(e) => treeHover(e, thirdTreeInfo, j, subTreeInfo, i, treeInfo, index)">
                        <span>{{ thirdTreeInfo.catalogueName }}</span>
                        <div class="poper-div"
                             :ref="'poper-div-'+thirdTreeInfo.id"
                             v-show="thirdTreeInfo.isShowPopover">
                          <span class="poper-item"
                                @click.stop="handleCatalogItem('contentSetting',thirdTreeInfo)">内容</span>
                          <el-divider direction="vertical"></el-divider>
                          <span class="poper-item"
                                @click.stop="handleCatalogItem('targetSetting',thirdTreeInfo)">目标</span>
                          <el-divider direction="vertical"></el-divider>
                          <span class="poper-item"
                                @click.stop="handleCatalogItem('edit',thirdTreeInfo)">编辑</span>
                          <el-divider direction="vertical"></el-divider>
                          <span class="poper-item"
                                @click.stop="handleCatalogItem('del',thirdTreeInfo)">删除</span>
                          <div class="popor-arrow"
                               style="left: 3.125vw"></div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="add-catalog-button-div">
          <el-button class="add-catalog-button"
                     type="primary"
                     @click="handleAddCatalog"
                     plain
                     round><i class="el-icon-plus"></i>添加</el-button>
        </div>
      </div>
      <div class="body-right"
           ref="bodyRight"
           :style="{ height: bodyRightHeight }">
        <div class="content-title">{{ contentTitle }}</div>
        <div class="first-panel"
             v-if="courseContentTargetInfo.length > 0">
          <div class="panel-header">
            <div class="title">
              <div class="title-icon"></div>目标清单
            </div>
            <div class="button"></div>
          </div>
          <div class="panel-content">
            <el-table class="tableDialog"
                      :data="courseContentTargetInfo"
                      style="width: 100%"
                      height="40vh"
                      ref="dialog-table"
                      v-loading="tableLoading">
              <el-table-column prop="descriptionCode"
                               label="指标编号"
                               width="200"
                               align="center"></el-table-column>
              <el-table-column prop="descriptionCode"
                               label="指标库"
                               width="250"
                               align="center"></el-table-column>
              <el-table-column prop="catalogueName"
                               label="目标名称"
                               align="center"
                               width="300"></el-table-column>
              <el-table-column prop="descriptionContent"
                               label="指标内容"
                               align="center"
                               width="300"></el-table-column>
              <el-table-column prop="targetSuggest"
                               label="教学建议"
                               width="120"
                               align="center"
                               fixed="right">
                <template slot-scope="scope">
                  <el-button type="text"
                             v-if="scope.row.sortIndex != 0"
                             class="button"
                             @click="handleTaggetItem('suggestView', scope.row)">查看</el-button>
                </template>
              </el-table-column>
              <el-table-column prop="targetPractice"
                               label="教学实践"
                               width="120"
                               align="center"
                               fixed="right">
                <template slot-scope="scope">
                  <el-button type="text"
                             v-if="scope.row.sortIndex != 0"
                             class="button"
                             @click="handleTaggetItem('practiceView', scope.row)">查看</el-button>
                </template>
              </el-table-column>
            </el-table>
          </div>
        </div>
        <div class="panel-data"
             v-if="courseContentList.length > 0">
          <div class="first-panel"
               v-for="(item, index) in courseContentList"
               :key="item.id">
            <div class="panel-header">
              <div class="title">
                <div class="title-icon"></div>{{ item.contentName }}
              </div>
              <div class="button">
                <el-tooltip class="item"
                            effect="light"
                            popper-class="customPopper"
                            content="附件上传"
                            placement="bottom"
                            v-if="item.eduCourseThemeCatalogueContentTrees.length == 0 && item.contentType == '0' && isUpdate">
                  <img :src="uploadFileIcon"
                       alt=""
                       @click="handleContentItem('addFile', null, null, item, index)"
                       v-if="item.eduCourseThemeCatalogueContentTrees.length == 0 && item.contentType == '0' && isUpdate"
                       style="margin-right: 0.41667vw" />
                </el-tooltip>
                <el-divider direction="vertical"
                            v-if="item.eduCourseThemeCatalogueContentTrees.length == 0 && item.contentType == '0' && isUpdate"></el-divider>
                <el-tooltip class="item"
                            effect="light"
                            popper-class="customPopper"
                            content="图文编辑"
                            placement="bottom"
                            v-if="item.eduCourseThemeCatalogueContentTrees.length == 0 && item.contentType == '0' && isUpdate">
                  <img :src="editIcon"
                       alt=""
                       @click="handleContentItem('editItem', null, null, item, index)"
                       v-if="item.eduCourseThemeCatalogueContentTrees.length == 0 && item.contentType == '0' && isUpdate"
                       style="margin: 0 0.41667vw" />
                </el-tooltip>
                <el-tooltip class="item"
                            effect="light"
                            popper-class="customPopper"
                            :content="item.contentType == '1' ? '视频添加' : '活动添加'"
                            placement="bottom"
                            v-if="item.eduCourseThemeCatalogueContentTrees.length == 0 && (item.contentType == '1' || item.contentType == '2') && isUpdate">
                  <img :src="videoAddIcon"
                       alt=""
                       @click="handleContentItem('addVideoOrActivity', null, null, item, index)"
                       v-if="item.eduCourseThemeCatalogueContentTrees.length == 0 && (item.contentType == '1' || item.contentType == '2') && isUpdate"
                       style="margin-right: 0.41667vw" />
                </el-tooltip>
                <el-divider direction="vertical"
                            v-if="item.eduCourseThemeCatalogueContentTrees.length == 0 && isUpdate"></el-divider>
                <img :src="firstPanelTurnIcon"
                     alt=""
                     @click="handleContentItem('turnItem', null, null, item, index)"
                     style="margin-left: 0.41667vw"
                     :class="{ 'turn-icon-class': !item.showFlag }" />
              </div>
            </div>
            <div class="panel-content"
                 v-show="item.showFlag"
                 v-if="item.eduCourseThemeCatalogueContentTrees.length > 0">
              <div class="second-panel"
                   v-for="(info, i) in item.eduCourseThemeCatalogueContentTrees"
                   :key="info.id">
                <div class="panel-header">
                  <div class="title">{{ info.contentName }}</div>
                  <div class="button">
                    <el-tooltip class="item"
                                effect="light"
                                popper-class="customPopper"
                                content="附件上传"
                                placement="bottom"
                                v-if="info.contentType == '0' && isUpdate">
                      <img :src="uploadFileIcon"
                           alt=""
                           @click="handleContentItem('addFile', info, i, item, index)"
                           v-if="info.contentType == '0' && isUpdate"
                           style="margin-right: 0.41667vw" />
                    </el-tooltip>
                    <el-divider direction="vertical"
                                v-if="info.contentType == '0' && isUpdate"></el-divider>
                    <el-tooltip class="item"
                                effect="light"
                                popper-class="customPopper"
                                content="图文编辑"
                                placement="bottom"
                                v-if="info.contentType == '0' && isUpdate">
                      <img :src="editIcon"
                           alt=""
                           @click="handleContentItem('editItem', info, i, item, index)"
                           v-if="info.contentType == '0' && isUpdate"
                           style="margin: 0 0.41667vw" />
                    </el-tooltip>
                    <el-tooltip class="item"
                                effect="light"
                                popper-class="customPopper"
                                :content="info.contentType == '1' ? '视频添加' : '活动添加'"
                                placement="bottom"
                                v-if="(info.contentType == '1' || info.contentType == '2') && isUpdate">
                      <img :src="videoAddIcon"
                           alt=""
                           @click="handleContentItem('addVideoOrActivity', info, i, item, index)"
                           v-if="(info.contentType == '1' || info.contentType == '2') && isUpdate"
                           style="margin-right: 0.41667vw" />
                    </el-tooltip>
                    <el-divider direction="vertical"
                                v-if="isUpdate"></el-divider>
                    <img :src="secondPanelTurnIcon"
                         alt=""
                         @click="handleContentItem('turnItem', info, i, item, index)"
                         style="margin-left: 0.41667vw"
                         :class="{ 'turn-icon-class': !info.showFlag }" />
                  </div>
                </div>
                <div class="panel-content"
                     v-show="info.showFlag"
                     v-if="info.contentType == '0'">
                  <div class="content-info"
                       v-if="info.eduCourseThemeContent.length > 0">
                    <!-- <div class="ql-container ql-snow">
                      <div class="ql-editor" v-html="info.eduCourseThemeContent[0].content"></div>
                    </div> -->
                    <div class="w-e-text-container">
                      <div class="w-e-text"
                           v-html="info.eduCourseThemeContent[0].content"></div>
                    </div>
                  </div>
                  <div class="file-info"
                       v-if="info.eduCourseThemeCatalogueContentFile">
                    <div class="file-item"
                         v-for="(fileInfo, fileIndex) in info.eduCourseThemeCatalogueContentFile"
                         :key="fileInfo.id"
                         :style="{ 'margin-right': ((fileIndex+1) % fileNum == 0) ? '0' : '3.5vw' }">
                      <div class="content">
                        <img class="icon"
                             :src="fileIconPDF"
                             alt=""
                             v-if="fileInfo.iconType == 'pdf'" />
                        <img class="icon"
                             :src="fileIconWord"
                             alt=""
                             v-if="fileInfo.iconType == 'doc'" />
                        <img class="icon"
                             :src="fileIconExcel"
                             alt=""
                             v-if="fileInfo.iconType == 'xls'" />
                        <img class="icon"
                             :src="fileIconPPT"
                             alt=""
                             v-if="fileInfo.iconType == 'ppt'" />
                        <div class="message">
                          <div class="title">{{ fileInfo.originalName }}</div>
                          <el-tooltip class="item"
                                      effect="light"
                                      popper-class="customPopper"
                                      :content="fileInfo.remark"
                                      placement="top-start">
                            <div class="ltitle">{{ fileInfo.remark }}</div>
                          </el-tooltip>
                        </div>
                      </div>
                      <div class="bottom">
                        <el-tooltip class="item"
                                    effect="light"
                                    popper-class="customPopper"
                                    content="附件预览"
                                    placement="bottom">
                          <img :src="fileViewIcon"
                               alt=""
                               @click="fileView(fileInfo)"
                               style="margin-right: 0.41667vw" />
                        </el-tooltip>
                        <el-divider direction="vertical"
                                    v-if="isUpdate"></el-divider>
                        <el-tooltip class="item"
                                    effect="light"
                                    popper-class="customPopper"
                                    content="附件删除"
                                    placement="bottom"
                                    v-if="isUpdate">
                          <img :src="delIcon"
                               alt=""
                               v-if="isUpdate"
                               @click="delFile(fileInfo)"
                               style="margin: 0 0.26042vw 0 0.41667vw" />
                        </el-tooltip>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="panel-content"
                     v-show="info.showFlag"
                     v-if="info.contentType == '1'">
                  <el-row>
                    <!--type="flex"-->
                    <el-col :span="videoSpan"
                            v-for="(video, k) in info.eduCourseThemeCatalogueContentVideo"
                            :key="video.id"
                            :style="{ 'margin-right': (k + 1) % videoNum != 0 ? '3.5vw' : '0', 'margin-bottom': '1.04167vw', }">
                      <!--'margin-right': (index + 1) % 4 != 0 ? '5.4%' : '0',-->
                      <el-card shadow="hover"
                               :body-style="{ padding: 'undefined' }"
                               @click.native="videoView(video)">
                        <div class="video-body"
                             :style="{ 'background-image': 'url(' + (video.fontImg ? video.fontImg : defaultImg) + ')', 'background-size': '100% 100%', }">
                          <img :src="videoPlayIcon"
                               alt="" />
                        </div>
                        <div class="info-body video-item">
                          <div class="title">{{ video.videoName }}</div>
                          <div class="ltitle">{{ video.intro }}</div>
                          <div class="bottom">
                            <div class="video-time">{{ video.createTime }}</div>
                            <div class="video-button">
                              <el-tooltip class="item"
                                          effect="light"
                                          popper-class="customPopper"
                                          content="视频编辑"
                                          placement="bottom"
                                          v-if="isUpdate">
                                <img :src="editIcon"
                                     alt=""
                                     v-if="isUpdate"
                                     @click.stop="editVideo(video, k, info, i, item, index)"
                                     style="margin-right: 15%" />
                              </el-tooltip>
                              <el-divider direction="vertical"
                                          v-if="isUpdate"></el-divider>
                              <el-tooltip class="item"
                                          effect="light"
                                          popper-class="customPopper"
                                          content="视频删除"
                                          placement="bottom"
                                          v-if="isUpdate">
                                <img :src="delIcon"
                                     alt=""
                                     v-if="isUpdate"
                                     @click.stop="delVideo(video)"
                                     style="margin-left: 15%" />
                              </el-tooltip>
                            </div>
                          </div>
                        </div>
                      </el-card>
                    </el-col>
                  </el-row>
                </div>
                <div class="panel-content"
                     v-show="info.showFlag"
                     v-if="info.contentType == '2'">
                  <!-- <el-radio-group v-model="info.activityTypeRadio" @change="(val) => handleRadioChange(val, info, i, item, index)">
                    <el-radio-button :label="radioInfo.value" v-for="radioInfo in activityTypeOptions" :key="radioInfo.id">{{ radioInfo.name }}</el-radio-button>
                  </el-radio-group> -->
                  <div class="activity-type-check"
                       v-show="info.activityListShow">
                    <div class="activity-type-item"
                         :class="{ active: radioInfo.value == info.activityTypeRadio }"
                         v-for="radioInfo in activityTypeOptions"
                         :key="radioInfo.id"
                         @click="handleRadioChange(radioInfo.value, info, i, item, index)">{{ radioInfo.name }}</div>
                  </div>
                  <div class="third-panel"
                       v-for="activityInfo in info.activityList"
                       :key="activityInfo.id">
                    <div class="panel-header">
                      <div class="title">
                        <img :src="activityTitleIcon"
                             alt="" /><span>{{ activityInfo.activityName }}</span>
                      </div>
                      <div class="button">
                        <el-tooltip class="item"
                                    effect="light"
                                    popper-class="customPopper"
                                    content="活动编辑"
                                    placement="bottom"
                                    v-if="isUpdate">
                          <img :src="detailEditIcon"
                               alt=""
                               v-if="isUpdate"
                               @click="handleActivityItem('edit', activityInfo, info, i, item, index)"
                               style="margin-right: 0.78125vw" />
                        </el-tooltip>
                        <el-tooltip class="item"
                                    effect="light"
                                    popper-class="customPopper"
                                    content="活动删除"
                                    placement="bottom"
                                    v-if="isUpdate">
                          <img :src="detailDelIcon"
                               alt=""
                               v-if="isUpdate"
                               @click="handleActivityItem('del', activityInfo, info, i, item, index)" />
                        </el-tooltip>
                      </div>
                    </div>
                    <div class="panel-content">
                      <div class="content-info"
                           v-if="activityInfo.activityContent">
                        <!-- <div class="ql-container ql-snow">
                          <div class="ql-editor" v-html="activityInfo.activityContent"></div>
                        </div> -->
                        <div class="w-e-text-container">
                          <div class="w-e-text"
                               v-html="activityInfo.activityContent"></div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="panel-content"
                 v-show="item.showFlag"
                 v-if="item.eduCourseThemeCatalogueContentTrees.length == 0">
              <div class="content-panel"
                   v-if="item.contentType == '0'">
                <div class="content-info"
                     v-if="item.eduCourseThemeContent.length > 0">
                  <!-- <div class="ql-container ql-snow">
                    <div class="ql-editor" v-html="item.eduCourseThemeContent[0].content"></div>
                  </div> -->
                  <div class="w-e-text-container">
                    <div class="w-e-text"
                         v-html="item.eduCourseThemeContent[0].content"></div>
                  </div>
                </div>
                <div class="file-info"
                     v-if="item.eduCourseThemeCatalogueContentFile">
                  <div class="file-item"
                       v-for="(fileInfo, fileIndex) in item.eduCourseThemeCatalogueContentFile"
                       :key="fileInfo.id"
                       :style="{ 'margin-right': ((fileIndex+1) % fileNum == 0) ? '0' : '3.5vw' }">
                    <div class="content">
                      <img class="icon"
                           :src="fileIconPDF"
                           alt=""
                           v-if="fileInfo.iconType == 'pdf'" />
                      <img class="icon"
                           :src="fileIconWord"
                           alt=""
                           v-if="fileInfo.iconType == 'doc'" />
                      <img class="icon"
                           :src="fileIconExcel"
                           alt=""
                           v-if="fileInfo.iconType == 'xls'" />
                      <img class="icon"
                           :src="fileIconPPT"
                           alt=""
                           v-if="fileInfo.iconType == 'ppt'" />
                      <div class="message">
                        <div class="title">{{ fileInfo.originalName }}</div>
                        <el-tooltip class="item"
                                    effect="light"
                                    popper-class="customPopper"
                                    :content="fileInfo.remark"
                                    placement="top-start">
                          <div class="ltitle">{{ fileInfo.remark }}</div>
                        </el-tooltip>
                      </div>
                    </div>
                    <div class="bottom">
                      <el-tooltip class="item"
                                  effect="light"
                                  popper-class="customPopper"
                                  content="附件预览"
                                  placement="bottom">
                        <img :src="fileViewIcon"
                             alt=""
                             @click="fileView(fileInfo)"
                             style="margin-right: 0.41667vw" />
                      </el-tooltip>
                      <el-divider direction="vertical"
                                  v-if="isUpdate"></el-divider>
                      <el-tooltip class="item"
                                  effect="light"
                                  popper-class="customPopper"
                                  content="附件删除"
                                  placement="bottom"
                                  v-if="isUpdate">
                        <img :src="delIcon"
                             alt=""
                             v-if="isUpdate"
                             @click="delFile(fileInfo)"
                             style="margin: 0 0.26042vw 0 0.41667vw" />
                      </el-tooltip>
                    </div>
                  </div>
                </div>
              </div>
              <div class="content-panel"
                   v-if="item.contentType == '1'">
                <el-row>
                  <!--type="flex"-->
                  <el-col :span="videoSpan"
                          v-for="(video, k) in item.eduCourseThemeCatalogueContentVideo"
                          :key="video.id"
                          :style="{ 'margin-right': (k + 1) % videoNum != 0 ? '3.5vw' : '0', 'margin-bottom': '1.04167vw', }">
                    <!--'margin-right': (index + 1) % 4 != 0 ? '5.4%' : '0',-->
                    <el-card shadow="hover"
                             :body-style="{ padding: 'undefined' }"
                             @click.native="videoView(video)">
                      <div class="video-body"
                           :style="{ 'background-image': 'url(' + (video.fontImg ? video.fontImg : defaultImg) + ')', 'background-size': '100% 100%', }">
                        <img :src="videoPlayIcon"
                             alt="" />
                      </div>
                      <div class="info-body video-item">
                        <div class="title">{{ video.videoName }}</div>
                        <div class="ltitle">{{ video.intro }}</div>
                        <div class="bottom">
                          <div class="video-time">{{ video.createTime }}</div>
                          <div class="video-button">
                            <el-tooltip class="item"
                                        effect="light"
                                        popper-class="customPopper"
                                        content="视频编辑"
                                        placement="bottom"
                                        v-if="isUpdate">
                              <img :src="editIcon"
                                   alt=""
                                   v-if="isUpdate"
                                   @click.stop="editVideo(video, k, null, null, item, index)"
                                   style="margin-right: 15%" />
                            </el-tooltip>
                            <el-divider direction="vertical"></el-divider>
                            <el-tooltip class="item"
                                        effect="light"
                                        popper-class="customPopper"
                                        content="视频删除"
                                        placement="bottom"
                                        v-if="isUpdate">
                              <img :src="delIcon"
                                   alt=""
                                   v-if="isUpdate"
                                   @click.stop="delVideo(video)"
                                   style="margin-left: 15%" />
                            </el-tooltip>
                          </div>
                        </div>
                      </div>
                    </el-card>
                  </el-col>
                </el-row>
              </div>
              <div class="content-panel"
                   v-if="item.contentType == '2'">
                <!-- <el-radio-group v-model="item.activityTypeRadio" @change="(val) => handleRadioChange(val, null, null, item, index)">
                  <el-radio-button :label="radioInfo.value" v-for="radioInfo in activityTypeOptions" :key="radioInfo.id">{{ radioInfo.name }}</el-radio-button>
                </el-radio-group> -->
                <div class="activity-type-check"
                     v-show="item.activityListShow">
                  <div class="activity-type-item"
                       :class="{ active: radioInfo.value == item.activityTypeRadio }"
                       v-for="radioInfo in activityTypeOptions"
                       :key="radioInfo.id"
                       @click="handleRadioChange(radioInfo.value, null, null, item, index)">{{ radioInfo.name }}</div>
                </div>
                <div class="third-panel"
                     v-for="activityInfo in item.activityList"
                     :key="activityInfo.id">
                  <div class="panel-header">
                    <div class="title">
                      <img :src="activityTitleIcon"
                           alt="" />
                      <span>{{ activityInfo.activityName }}</span>
                    </div>
                    <div class="button">
                      <el-tooltip class="item"
                                  effect="light"
                                  popper-class="customPopper"
                                  content="活动编辑"
                                  placement="bottom"
                                  v-if="isUpdate">
                        <img :src="detailEditIcon"
                             alt=""
                             v-if="isUpdate"
                             @click="handleActivityItem('edit', activityInfo, null, null, item, index)"
                             style="margin-right: 0.78125vw" />
                      </el-tooltip>
                      <el-tooltip class="item"
                                  effect="light"
                                  popper-class="customPopper"
                                  content="活动删除"
                                  placement="bottom"
                                  v-if="isUpdate">
                        <img :src="detailDelIcon"
                             alt=""
                             v-if="isUpdate"
                             @click="handleActivityItem('del', activityInfo, null, null, item, index)" />
                      </el-tooltip>
                    </div>
                  </div>
                  <div class="panel-content">
                    <div class="content-info"
                         v-if="activityInfo.activityContent">
                      <!-- <div class="ql-container ql-snow">
                        <div class="ql-editor" v-html="activityInfo.activityContent"></div>
                      </div> -->
                      <div class="w-e-text-container">
                        <div class="w-e-text"
                             v-html="activityInfo.activityContent"></div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="empty-content-panel"
             v-if="courseContentList.length == 0">
          <img class="empty-img"
               :src="emptyImg"
               alt="" />
          <div class="empty-title">暂无内容</div>
          <!-- <div class="empty-ltitle">需要先进行内容设置后才能进行内容操作</div> -->
          <el-button class="empty-button"
                     type="primary"
                     plain
                     round
                     @click="handleCourseContentCatalog"
                     v-if="isUpdate">内容设置</el-button>
        </div>
      </div>
    </div>
    <el-dialog :title="courseContentTitle"
               :visible.sync="courseContentVisible"
               :append-to-body="true"
               :destroy-on-close="true"
               @before-close="handleClose"
               @close="handleClose"
               width="50%">
      <el-form :model="courseContentForm"
               ref="courseContentForm"
               :rules="courseContentRules"
               label-width="6.25vw">
        <el-row>
          <el-col :span="24">
            <el-form-item class="form-choose-item"
                          label="名称："
                          prop="courseThemeName">
              <el-input class="form-input"
                        v-model="courseContentForm.courseThemeName"
                        placeholder="请输入名称"
                        :maxlength="50"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="24">
            <el-form-item label="封面："
                          class="form-choose-item">
              <el-upload class="avatar-uploader"
                         :action="uploadUrl"
                         :headers="uploadHeader"
                         :show-file-list="false"
                         :on-success="handleContentAvatarSuccess"
                         :before-upload="beforeContentAvatarUpload"
                         :accept="'.png,.jpg,.jpeg'">
                <img v-if="courseContentForm.coverImg"
                     :src="courseContentForm.coverImg"
                     class="avatar" />
                <i v-if="courseContentForm.coverImg"
                   class="el-icon-error delete-icon"
                   @click.stop="delCourseContentImg"></i>
                <div v-else
                     class="avatar-uploader-info">
                  <img class="avatar-uploader-icon"
                       :src="uploadImgIcon" />
                  <div class="avatar-uploader-title">上传图片</div>
                  <div class="avatar-uploader-tip">图片尺寸比例4:3，仅限上传1张</div>
                </div>
              </el-upload>
              <!-- <div class="upload-tip">图片尺寸比例4:3，仅限上传1张</div> -->
            </el-form-item>
          </el-col>
          <el-col :span="24">
            <el-form-item class="form-choose-item"
                          label="描述：">
              <el-input class="form-input"
                        type="textarea"
                        :rows="5"
                        placeholder="请填写描述内容..."
                        v-model="courseContentForm.description"
                        resize="none"
                        :maxlength="100"
                        show-word-limit></el-input>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <div slot="footer"
           class="dialog-footer">
        <el-button :loading="buttonloading"
                   @click="handleClose">取 消</el-button>
        <el-button :loading="buttonloading"
                   type="primary"
                   @click="handleSubmit">保 存</el-button>
      </div>
    </el-dialog>
    <el-dialog :title="courseContentCatalogTitle"
               :visible.sync="courseContentCatalogVisible"
               :append-to-body="true"
               :destroy-on-close="true"
               @before-close="handleCourseContentCatalogClose"
               @close="handleCourseContentCatalogClose"
               width="50%">
      <el-form :model="courseContentCatalogForm"
               ref="courseContentCatalogForm"
               :rules="courseContentCatalogRules"
               label-width="6.25vw">
        <el-row>
          <el-col :span="24">
            <el-form-item class="form-choose-item"
                          label="名称："
                          prop="catalogueName">
              <el-input class="form-input"
                        v-model="courseContentCatalogForm.catalogueName"
                        placeholder="请输入名称"
                        :maxlength="50"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="24">
            <el-form-item class="form-choose-item"
                          label="是否必填：">
              <el-switch v-model="courseContentCatalogForm.isRequired">
              </el-switch>
            </el-form-item>
          </el-col>
          <el-col :span="24">
            <el-form-item class="form-choose-item"
                          label="家长展示：">
              <el-switch v-model="courseContentCatalogForm.isOpen"> </el-switch>
            </el-form-item>
          </el-col>
          <el-col :span="24">
            <el-form-item class="form-choose-item"
                          label="集体活动：">
              <el-switch v-model="courseContentCatalogForm.isTeam"> </el-switch>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <div slot="footer"
           class="dialog-footer">
        <el-button :loading="buttonloading"
                   @click="handleCourseContentCatalogClose">取 消</el-button>
        <el-button :loading="buttonloading"
                   type="primary"
                   @click="handleCourseContentCatalogSubmit">保 存</el-button>
      </div>
    </el-dialog>
    <el-dialog :title="courseActivityTitle"
               :visible.sync="courseActivityVisible"
               :append-to-body="true"
               :destroy-on-close="true"
               @before-close="handleCourseActivityClose"
               @close="handleCourseActivityClose"
               width="70%">
      <el-form :model="courseActivityForm"
               ref="courseActivityForm"
               :rules="rules"
               label-width="6.25vw">
        <el-row>
          <el-col :span="24">
            <el-form-item class="form-choose-item"
                          label="活动分类："
                          prop="activityClassify">
              <el-select class="form-input"
                         v-model="courseActivityForm.activityClassify"
                         clearable
                         placeholder="请选择活动分类">
                <el-option v-for="item in activityTypeOptions"
                           :key="item.value"
                           :label="item.name"
                           :value="item.value"></el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="24">
            <el-form-item class="form-choose-item"
                          label="活动名称："
                          prop="activityName">
              <el-input class="form-input"
                        v-model="courseActivityForm.activityName"
                        placeholder="请输入活动名称"
                        :maxlength="50"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="24">
            <el-form-item class="form-choose-item"
                          label="内容：">
              <!-- <quill-editor style="width: 100%;" class="textEditor" v-model="courseActivityForm.activityContent" :options="editorOption" ref="QuillEditor"></quill-editor> -->
              <editor-component style="width: 100%"
                                ref="activityContentComponent"
                                :id="'activityContent'"
                                :height="'300'"
                                :defaultContent="editorContent"
                                @getContent="getContent"></editor-component>
            </el-form-item>
          </el-col>
          <!-- <el-col :span="24" style="width: 0">
            <el-upload style="opacity: 0" :action="uploadUrl" :headers="uploadHeader" :show-file-list="false" :on-success="handleAvatarSuccess" :before-upload="beforeAvatarUpload">
              <el-button id="uploadButton" size="small" type="primary">点击上传</el-button>
            </el-upload>
          </el-col> -->
        </el-row>
      </el-form>
      <div slot="footer"
           class="dialog-footer">
        <el-button :loading="buttonloading"
                   @click="handleCourseActivityClose">取 消</el-button>
        <el-button :loading="buttonloading"
                   type="primary"
                   @click="handleCourseActivitySubmit">保 存</el-button>
      </div>
    </el-dialog>
    <catalog-setting :catalogSettingDialogVisible.sync="courseSettingDialogVisible"
                     :catalogSettingDialogHandleType="handleType"
                     :catalogSettingDialogType="courseSettingType"
                     :formData="courseSettingData"
                     :courseId="courseContentCatalogItemId"
                     @refreshPage="refreshPage"></catalog-setting>
    <target-setting :targetSettingDialogVisible.sync="targetSettingDialogVisible"
                    :targetSettingDialogHandleType="targetSettingDialogHandleType"
                    :formData="targetSettingData"
                    :courseContentCatalogId="courseContentCatalogItemId"
                    @refreshPage="getTargetInfo"></target-setting>
    <content-component ref="contentComponent"
                       :contentDialogVisible.sync="contentDialogVisible"
                       :contentDialogHandleType="contentDialogHandleType"
                       :contentDialogType="contentDialogType"
                       :formData="contentData"
                       @getFileData="getContentInfo"></content-component>
    <upload-video ref="uploadVideo"
                  :uploadVideoDialogVisible.sync="uploadVideoDialogVisible"
                  :uploadVideoHandleType="uploadVideoHandleType"
                  :formData="videoData"
                  @getFileData="getVideoInfo"></upload-video>
    <upload-file ref="uploadFile"
                 :uploadFileDialogVisible.sync="uploadFileDialogVisible"
                 :uploadFileHandleType="uploadFileHandleType"
                 :formData="fileData"
                 @getFileData="getFileInfo"></upload-file>
    <suggests-view-dialog :suggestsViewDialogVisible.sync="suggestsViewDialogVisible"
                          :courseContentCatalogTargetId="courseContentCatalogTargetId"
                          @closeDialog="suggestsDialogClose"></suggests-view-dialog>
    <practices-view-dialog :practicesViewDialogVisible.sync="practicesViewDialogVisible"
                           :courseContentCatalogTargetId="courseContentCatalogTargetId"
                           :courseContentCatalogTargetFlag="courseContentCatalogTargetFlag"
                           :courseContentCatalogTargetTypeValue="courseContentCatalogTargetTypeValue"
                           @closeDialog="practicesDialogClose"></practices-view-dialog>
    <video-view-dialog ref="videoView"
                       :videoViewDialogVisible.sync="videoViewDialogVisible"
                       :type="videoViewType"
                       :videoUrl="videoViewUrl"
                       @closeDialog="videoViewClose"></video-view-dialog>
  </div>
</template>
<script>
// 工具栏配置
const toolbarOptions = [
  ["bold", "italic", "underline", "strike"], // toggled buttons
  [{ header: 1 }, { header: 2 }], // custom button values
  [{ list: "ordered" }, { list: "bullet" }],
  [{ indent: "-1" }, { indent: "+1" }], // outdent/indent
  [{ direction: "rtl" }], // text direction
  [{ size: ["small", false, "large", "huge"] }], // custom dropdown
  [{ header: [1, 2, 3, 4, 5, 6, false] }],
  [{ color: [] }, { background: [] }], // dropdown with defaults from theme
  [{ font: [] }],
  [{ align: [] }],
  ["link", "image", "video"],
  ["clean"], // remove formatting button
];
import { mapGetters, mapState } from "vuex";
import EditorComponent from "@/components/editorComponent/index.vue";
import CatalogSetting from "@/components/catalogSetting/index.vue";
import TargetSetting from "@/components/targetSetting/index.vue";
import contentComponent from "@/components/contentComponent/index.vue";
import uploadVideo from "@/components/uploadVideo/index.vue";
import uploadFile from "@/components/uploadFile/index.vue";
import SuggestsViewDialog from "./components/suggestsDialog.vue";
import PracticesViewDialog from "./components/practicesDialog.vue";
import VideoViewDialog from "@/components/videoView/index.vue";
import detailTeacherIcon from "@/assets/img/detail-teacher-icon.png";
import detailTimeIcon from "@/assets/img/detail-time-icon.png";
import detailPushIcon from "@/assets/img/detail-push-icon.png";
import detailEditIcon from "@/assets/img/detail-edit-icon.png";
import detailDelIcon from "@/assets/img/detail-del-icon.png";
import detailPushIconHeader from "@/assets/img/detail-push-icon-header.png";
import detailEditIconHeader from "@/assets/img/detail-edit-icon-header.png";
import detailDelIconHeader from "@/assets/img/detail-del-icon-header.png";
import emptyImg from "@/assets/img/empty-img.png";
import firstPanelTurnIcon from "@/assets/img/first-panel-turn-icon.png";
import editIcon from "@/assets/img/edit-icon.png";
import uploadFileIcon from "@/assets/img/upload-file-icon.png";
import panelDelIcon from "@/assets/img/panel-del-icon.png";
import fileViewIcon from "@/assets/img/file-view-icon.png";
import delIcon from "@/assets/img/del-icon.png";
import videoAddIcon from "@/assets/img/video-add-icon.png";
import secondPanelTurnIcon from "@/assets/img/second-panel-turn-icon.png";
import catalogSettingIcon from "@/assets/img/catalog-setting-icon.png";
import activityTitleIcon from "@/assets/img/activity-title-icon.png";
import fileIconPDF from "@/assets/img/file-icon-PDF.png";
import fileIconWord from "@/assets/img/file-icon-word.png";
import fileIconExcel from "@/assets/img/file-icon-excel.png";
import fileIconPPT from "@/assets/img/file-icon-ppt.png";
import videoPlayIcon from "@/assets/img/play-icon.png";
import defaultImg from "@/assets/img/default-img.png";
import defaultHeaderImg from "@/assets/img/default-header-img.png";
import defaultHeaderBg from "@/assets/img/detail-header-bg.png";
import uploadImgIcon from '@/assets/img/upload-img-icon.png';
import { getToken } from "@/utils/auth";
import { noSymbol } from "@/utils/validate.js";
import { getPermissionButton } from "@/utils/getUrlPermissionButtons";
export default {
  name: "courseContentSetting",
  components: { EditorComponent, CatalogSetting, TargetSetting, contentComponent, uploadVideo, uploadFile, SuggestsViewDialog, PracticesViewDialog, VideoViewDialog, },
  data () {
    let checkName = (rule, value, callback) => {
      if (value === "") {
        return callback();
      }
      if (value !== "" && !noSymbol(value)) {
        callback(new Error("只支持汉字、数字、字母组合"));
      } else {
        callback();
      }
    };
    return {
      courseContentTargetInfo: [], //目标清单list
      tableLoading: false, //目标清单tableloading
      activeTab: "courseIntroduce", //介绍和内容切换
      courseContentInfo: {
        coverImg: "",
        courseThemeName: "",
        description: "",
        status: "",
        userName: "",
        createTime: "",
      }, //课程基本信息
      detailTeacherIcon, //教师信息icon
      detailTimeIcon, //创建时间icon
      detailPushIcon, //发布icon
      detailEditIcon, //详情编辑icon
      detailDelIcon, //详情删除icon
      detailPushIconHeader, //发布iconnew
      detailEditIconHeader, //详情编辑iconnew
      detailDelIconHeader, //详情删除iconnew
      emptyImg, //空数据图片
      firstPanelTurnIcon, //一级折叠icon
      editIcon, //编辑icon
      uploadFileIcon, //附件上传icon
      panelDelIcon, //层删除icon
      fileViewIcon, //文件预览icon
      delIcon, //删除icon
      videoAddIcon, //视频添加icon
      secondPanelTurnIcon, //二级折叠icon
      catalogSettingIcon, //课程内容的目录icon
      activityTitleIcon, //活动头部icon
      fileIconPDF, //pdfIcon
      fileIconWord, //wordIcon
      fileIconExcel, //excelIcon
      fileIconPPT, //PPTIcon
      videoPlayIcon, //视频icon
      defaultImg, //默认图片
      defaultHeaderImg, //头部信息默认图片
      defaultHeaderBg, //头部背景图
      uploadImgIcon, //上传按钮

      courseActivityTitle: "", //课程内容活动信息弹窗标题
      courseActivityVisible: false, //课程活动信息弹窗显隐标识
      courseActivityHandleType: "", //课程活动信息弹窗操作类型
      courseActivityForm: {
        activityClassify: "",
        activityName: "",
        // activityContent: "",
      }, //查询条件
      rules: {
        activityName: [
          // { required: true, message: "请输入活动名称，支持汉字、数字、字母组合", trigger: "blur", },
          { min: 2, message: "活动名称长度不能小于2", trigger: "blur" },
          // { validator: checkName, trigger: "blur" },
        ],
        activityClassify: [
          { required: true, message: "请选择活动分类", trigger: "change" },
        ],
      },
      activityContent: "", //改变的值

      courseContentCatalogTitle: "", //课程内容目录信息弹窗标题
      courseContentCatalogVisible: false, //课程内容目录信息弹窗显隐标识
      courseContentCatalogForm: {
        catalogueName: "",
        isRequired: true,
        isOpen: false,
        isTeam: false,
      }, //课程内容目录信息form
      courseContentCatalogRules: {
        catalogueName: [
          { required: true, message: "请输入名称", trigger: "blur", },
          { min: 2, message: "名称长度不能小于2", trigger: "blur" },
        ],
      },

      courseId: null, //课程id
      courseContentId: null, //编辑课程内容id
      courseContentList: [], //课程内容详情
      handleType: "", //操作类型
      contentTitle: "", //选中目录的标题

      courseContentCatalogId: null, //课程内容的目录id
      courseContentCatalogItemId: null, //操作目录id
      parentCatalogId: null, //父级目录id
      secondInfo: {}, //二级对象
      secondIndex: null, //二级对象下标
      firstInfo: {}, //一级对象
      firstIndex: null, //一级对象下标

      courseSettingDialogVisible: false, //内容设置弹窗
      courseSettingData: {}, //内容设置传值
      courseSettingType: "", //内容设置类型

      targetSettingDialogVisible: false, //指标设置弹窗显隐标识
      targetSettingDialogHandleType: "", //指标设置弹窗类型
      targetSettingData: {}, //指标设置弹窗数据

      courseContentTitle: "", //主题or活动信息弹窗标题
      courseContentVisible: false, //主题or活动信息弹窗显隐标识
      courseContentForm: {
        courseThemeName: "",
        coverImg: "",
        description: "",
      }, //弹窗form条件
      courseContentRules: {
        courseThemeName: [
          { required: true, message: "请输入名称", trigger: "blur" },
          { min: 2, message: "名称长度不能小于2", trigger: "blur" },
          // { validator: checkName, trigger: 'blur' }
        ],
      },

      buttonloading: false, //弹框按钮loading
      pageLoading: false, //页面loading
      uploadUrl: (process.env.NODE_ENV == "development" ? process.env.VUE_APP_LOCAL_URL + "/webForm" : process.env.VUE_APP_LOCAL_URL) + "/api/upload/uploadImgToOSS", //上传地址
      uploadHeader: { token: this.getToken() },
      editorOption: {
        //富文本配置
        modules: {
          toolbar: {
            container: toolbarOptions, // 工具栏
            handlers: {
              image: function (value) {
                if (value) {
                  document.getElementById("uploadButton").click();
                } else {
                  this.quill.format("image", false);
                }
              },
            },
          },
        },
      },

      contentDialogVisible: false, //内容编辑弹窗标识
      contentDialogHandleType: "", //内容编辑弹窗操作类型
      contentDialogType: "", //内容弹窗类型
      contentData: {}, //内容弹窗数据

      uploadVideoDialogVisible: false, //视频上传弹窗标识
      uploadVideoHandleType: "", //视频上传处理类型
      videoData: {}, //视频上传数据

      uploadFileDialogVisible: false, //文件上传弹窗标识
      uploadFileHandleType: "", //文件上传处理类型
      fileData: {}, //文件上传数据
      fileTypeList: ["png", "jpg", "jpeg"], //文件类型list

      catalogData: [], //目录结构

      bodyLeftHeight: "100%",
      bodyRightHeight: "100%",
      suggestsViewDialogVisible: false, //目标建议查看显隐标识
      practicesViewDialogVisible: false, //目标实践查看显隐标识
      courseContentCatalogTargetId: null, //目标id
      courseContentCatalogTargetFlag: "", //目标维度
      courseContentCatalogTargetTypeValue: "", //目标维度字典值

      videoViewDialogVisible: false, //视频预览标识
      videoViewType: "", //视频类型
      videoViewUrl: "", //视频预览url

      videoSpan: 6, //video宽度
      videoNum: 4, //video个数
      fileNum: 3, //附件个数

      editorContent: "", //编辑器初始化内容

      permissionButtonList: [], //权限按钮list
      isUpdate: false,
    };
  },
  computed: {
    ...mapGetters(["permissions"]),
    ...mapState({
      typeOptions: (state) => state.common.courseTypeList,
      areaOptions: (state) => state.common.courseFieldList,
      stepOptions: (state) => state.common.ageScopeList,
      levelOptions: (state) => state.common.courseLevelList,
      activityTypeOptions: (state) => state.common.activityTypeList,
      menuList: (state) => state.common.menuList,
    }),
  },
  watch: {
    permissionButtonList: {
      handler (val) {
        if (val) {
          this.isUpdate = val.indexOf("course:update") != -1;
        }
      },
      deep: true,
    },
    menuList: {
      handler (val) {
        if (val) {
          this.getPermissionButton("/courseManage", (data) => {
            this.permissionButtonList = data;
          });
        }
      },
      deep: true,
    },
  },
  created () {
    this.courseContentId = Number(this.$route.query.id);
    this.courseId = Number(this.$route.query.courseId)
    this.getPermissionButton("/courseManage", (data) => {
      this.permissionButtonList = data;
    });
    let that = this
    window.onresize = function () {
      that.resizeVideoInfo()
      that.resizeHeight()
    }
    that.resizeVideoInfo()
  },
  mounted () {
    this.pageLoading = true;
    this.getData();
    this.getCourseContentCatalogInfo();
  },
  methods: {
    getToken,
    getPermissionButton,
    //调整video个数和宽度
    resizeVideoInfo () {
      console.log(window.outerWidth)
      if (window.outerWidth <= 1800 && window.outerWidth > 1300) {
        this.videoSpan = 7
        this.videoNum = 3
        this.fileNum = 2
      } else if (window.outerWidth <= 1300) {
        this.videoSpan = 10
        this.videoNum = 2
        this.fileNum = 2
      } else if (window.outerWidth > 1800) {
        this.videoSpan = 5
        this.videoNum = 4
        this.fileNum = 3
      }
    },
    //调整height
    resizeHeight () {
      console.log(this.$refs.bodyLeft.offsetHeight);
      console.log(this.$refs.bodyRight.offsetHeight);
      this.bodyLeftHeight = "auto";
      this.bodyRightHeight = "auto";
      this.$nextTick(() => {
        this.bodyLeftHeight = this.$refs.bodyRight.offsetHeight + "px";
        this.bodyRightHeight = this.$refs.bodyRight.offsetHeight + "px";
        if (
          this.$refs.bodyLeft.offsetHeight > this.$refs.bodyRight.offsetHeight
        ) {
          this.bodyLeftHeight = this.$refs.bodyLeft.offsetHeight + "px";
          this.bodyRightHeight = this.$refs.bodyLeft.offsetHeight + "px";
        }
      });
    },
    //获取课程内容详情
    getData (callback) {
      this.$api.getCourseContentInfo(this.courseContentId).then((res) => {
        if (res.data.code === 0) {
          this.courseContentInfo = { ...res.data.data };
        } else {
          this.$message.error(res.data.msg);
        }
        callback && callback();
      });
    },
    //获取课程内容目录list
    getCourseContentCatalogInfo () {
      this.$api.getCourseContentCataLogList({ courseThemeId: this.courseContentId }).then((res) => {
        if (res.data.code === 0) {
          res.data.data.map((item) => {
            item.isShowPopover = false
            if (item.children) {
              item.children.map((info) => {
                info.isShowPopover = false
                if (info.children) {
                  info.children.map((result) => {
                    result.isShowPopover = false
                  })
                }
              })
            }
          })
          this.catalogData = res.data.data;
          this.courseContentCatalogId = res.data.data[0].id;
          this.courseContentCatalogItemId = res.data.data[0].id;
          this.contentTitle = res.data.data[0].catalogueName;
          this.getCourseContentCatalogContentInfo();
          this.getCourseContentCatalogTargetList();
        } else {
          this.$message.error(res.data.msg);
        }
      });
    },
    //目录移入
    treeHover (e, thirdInfo, thirdIndex, secondInfo, secondIndex, firstInfo, firstIndex) {
      if (this.setTimeoutOject) {
        clearTimeout(this.setTimeoutOject)
        this.setTimeoutOject = null
      }
      this.setTimeoutOject = setTimeout(() => {
        let info = thirdIndex !== null ? { ...thirdInfo } : (secondIndex !== null ? { ...secondInfo } : { ...firstInfo })
        let type = thirdIndex !== null ? 'third' : (secondIndex !== null ? 'second' : 'first')
        console.log('info', info)
        console.log('type', type)
        console.log('e', e)
        this.catalogData.map((item) => {
          item.isShowPopover = false
          if (item.children) {
            item.children.map((info) => {
              info.isShowPopover = false
              if (info.children) {
                info.children.map((result) => {
                  result.isShowPopover = false
                })
              }
            })
          }
        })
        this.$nextTick(() => {
          if (e.target.contains(this.$refs['poper-div-' + info.id][0]) || e.target.parentNode.contains(this.$refs['poper-div-' + info.id][0]) || e.target.contains(this.$refs['tree-title-' + info.id][0])) {
            if (!info.isShowPopover) {
              info.isShowPopover = true
            }
          }
          if (type == 'first') {
            this.$set(this.catalogData, firstIndex, info)
          } else if (type == 'second') {
            this.$set(this.catalogData[firstIndex].children, secondIndex, info)
          } else if (type == 'third') {
            this.$set(this.catalogData[firstIndex].children[secondIndex].children, thirdIndex, info)
          }
        })
      }, 300)
    },
    //目录移出
    treeLeave () {
      this.catalogData.map((item) => {
        item.isShowPopover = false
        if (item.children) {
          item.children.map((info) => {
            info.isShowPopover = false
            if (info.children) {
              info.children.map((result) => {
                result.isShowPopover = false
              })
            }
          })
        }
      })
    },
    //添加目录
    handleAddCatalog () {
      this.handleType = "add";
      this.courseContentCatalogVisible = true;
      this.$nextTick(() => {
        this.courseContentCatalogTitle = "添加";
      });
    },
    //编辑目录
    handleCatalogItem (type, row) {
      switch (type) {
        case "contentSetting":
          this.handleType = "edit";
          this.courseSettingDialogVisible = true;
          this.$nextTick(() => {
            this.courseContentCatalogItemId = row.id;
            this.courseSettingType = "content";
            // this.courseContentId = ""
            // this.contentSettingData = {}
          });
          break;
        case "edit":
          this.handleType = "edit";
          this.courseContentCatalogVisible = true;
          this.$nextTick(() => {
            this.courseContentCatalogTitle = "编辑";
            this.courseContentCatalogItemId = row.id;
            this.parentCatalogId = row.parentCatalogueId;
            this.courseContentCatalogForm.catalogueName = row.catalogueName;
            this.courseContentCatalogForm.isRequired = row.isRequired == "1" ? true : false;
            this.courseContentCatalogForm.isOpen = row.isOpen == "1" ? true : false;
            this.courseContentCatalogForm.isTeam = row.isTeam == "1" ? true : false;
          });
          break;
        case "add":
          this.handleType = "add";
          this.courseContentCatalogVisible = true;
          this.$nextTick(() => {
            this.courseContentCatalogTitle = "添加";
            this.parentCatalogId = row.id;
            // this.courseContentId = ""
            // this.courseContentCatalogForm = {}
          });
          break;
        case "del":
          this.deleteCourseContent(row.id);
          break;
        case "targetSetting":
          this.handleType = "edit";
          this.targetSettingDialogVisible = true;
          this.$nextTick(() => {
            this.courseContentCatalogItemId = row.id;
            this.targetSettingDialogHandleType = "edit";
            // this.courseContentId = ""
            // this.targetSettingData = {}
          });
          break;
      }
    },
    //删除
    deleteCourseContent (id) {
      //"确定删除该层目录, 是否继续?"
      const h = this.$createElement;
      this.$confirm(
        h("p", null, [
          h("span", { style: "color: rgba(0, 0, 0, 0.85);font-size: 16px;font-weight: 500;", }, "确定要删除该层目录？"),
          h("div", { style: "color: rgba(0, 0, 0, 0.65);font-size: 14px;margin-top: 10px", }, "是否确定删除该层目录，删除后，将无法恢复。"),
        ]), "提示", { confirmButtonText: "确定", cancelButtonText: "取消", iconClass: "el-icon-question", type: "warning", customClass: "warningMessage", }
      ).then(() => {
        this.$api.courseContentCataLogHandle("/api/educoursethemecatalogue/del", { id: id, delFlag: "1" }, "del").then((res) => {
          if (res.data.code === 0) {
            this.$message({
              type: "success", message: "删除成功!", duration: 2000, onClose: () => {
                this.pageLoading = true
                this.getCourseContentCatalogInfo();
              },
            });
          } else {
            this.$message.error(res.data.msg);
          }
        });
      }).catch(() => { });
    },
    //目录弹框关闭
    handleCourseContentCatalogClose () {
      this.courseContentCatalogVisible = false;
      this.$nextTick(() => {
        this.courseContentCatalogTitle = "";
        this.courseContentCatalogForm = {
          catalogueName: "",
          isRequired: true,
          isOpen: false,
          isTeam: false,
        };
        this.$refs.courseContentCatalogForm.clearValidate();
        // if (this.handleType == "edit") {
        //   this.parentCatalogId = null;
        //   this.handleType == "";
        // }
        this.courseContentCatalogItemId = null
        this.parentCatalogId = null;
        this.handleType == "";
      });
    },
    //目录弹窗完成
    handleCourseContentCatalogSubmit () {
      this.$refs.courseContentCatalogForm.validate((valid) => {
        if (valid) {
          this.buttonloading = true;
          let params = {
            catalogueName: this.courseContentCatalogForm.catalogueName,
            courseThemeId: this.courseContentId,
            isRequired: this.courseContentCatalogForm.isRequired ? "1" : "0",
            isOpen: this.courseContentCatalogForm.isOpen ? "1" : "0",
            isTeam: this.courseContentCatalogForm.isTeam ? "1" : "0",
            parentCatalogueId: this.parentCatalogId,
          };
          let url = "";
          if (this.handleType == 'edit') {
            params.id = this.courseContentCatalogItemId;
            url = "/api/educoursethemecatalogue";
          } else {
            url = "/api/educoursethemecatalogue/save";
          }
          this.$api.courseContentCataLogHandle(url, params, this.handleType).then((res) => {
            if (res.data.code === 0) {
              this.$message({
                message: "保存成功", type: "success", duration: 2000, onClose: () => {
                  this.buttonloading = false;
                  // this.newCourseContentCatalogId = res.data.data;
                  // if (this.handleType == "add") {
                  //   this.contentSettingDialogVisible = true;
                  //   this.$nextTick(() => {
                  //     this.courseContentCatalogId = res.data.data;
                  //     this.contentSettingType = "content";
                  //     // this.contentSettingData = {}
                  //   });
                  // } else {
                  this.handleCourseContentCatalogClose();
                  this.$nextTick(() => {
                    this.pageLoading = true
                    this.getCourseContentCatalogInfo();
                  })
                  // }
                },
              });
            } else {
              this.buttonloading = false;
              this.$message.error(res.data.msg);
            }
          });
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    //获取课程内容目录的内容list
    getCourseContentCatalogContentInfo () {
      this.$api.getCourseContentCatalogInfo({ id: this.courseContentCatalogId }).then((res) => {
        this.pageLoading = false;
        if (res.data.code === 0) {
          setTimeout(() => {
            if (res.data.data.length > 0) {
              res.data.data.map((item, index) => {
                item.showFlag = true;
                if (item.contentType == "2") {
                  console.log(this.activityTypeOptions);
                  item.activityTypeRadio = this.activityTypeOptions[0].value;
                  if (item.eduActivity && item.eduActivity.length > 0) {
                    item.activityListShow = true
                  } else {
                    item.activityListShow = false
                  }
                  this.getCourseContentCatalogActivityList(item.id, this.activityTypeOptions[0].value, null, null, item, index);
                }
                if (item.eduCourseThemeCatalogueContentFile && item.eduCourseThemeCatalogueContentFile.length > 0) {
                  item.eduCourseThemeCatalogueContentFile.map((fileInfo) => {
                    let type = fileInfo.fileUrl.substr(fileInfo.fileUrl.lastIndexOf("."), fileInfo.fileUrl.length);
                    if (type.toLowerCase().indexOf("pdf") != -1) {
                      fileInfo.iconType = "pdf";
                    }
                    if (type.toLowerCase().indexOf("doc") != -1) {
                      fileInfo.iconType = "doc";
                    }
                    if (type.toLowerCase().indexOf("xls") != -1) {
                      fileInfo.iconType = "xls";
                    }
                    if (type.toLowerCase().indexOf("ppt") != -1) {
                      fileInfo.iconType = "ppt";
                    }
                  });
                }
                if (item.eduCourseThemeCatalogueContentTrees) {
                  item.eduCourseThemeCatalogueContentTrees.map((info, i) => {
                    info.showFlag = true;
                    if (info.contentType == "2") {
                      info.activityTypeRadio = this.activityTypeOptions[0].value;
                      if (info.eduActivity && info.eduActivity.length > 0) {
                        info.activityListShow = true
                      } else {
                        info.activityListShow = false
                      }
                      this.getCourseContentCatalogActivityList(info.id, this.activityTypeOptions[0].value, info, i, item, index);
                    }
                    if (info.eduCourseThemeCatalogueContentFile && info.eduCourseThemeCatalogueContentFile.length > 0) {
                      info.eduCourseThemeCatalogueContentFile.map((fileInfo) => {
                        let type = fileInfo.fileUrl.substr(fileInfo.fileUrl.lastIndexOf("."), fileInfo.fileUrl.length);
                        if (type.toLowerCase().indexOf("pdf") != -1) {
                          fileInfo.iconType = "pdf";
                        }
                        if (type.toLowerCase().indexOf("doc") != -1) {
                          fileInfo.iconType = "doc";
                        }
                        if (type.toLowerCase().indexOf("xls") != -1) {
                          fileInfo.iconType = "xls";
                        }
                        if (type.toLowerCase().indexOf("ppt") != -1) {
                          fileInfo.iconType = "ppt";
                        }
                      });
                    }
                  });
                }
              });
              this.courseContentList = res.data.data;
              setTimeout(() => {
                this.resizeHeight();
              }, 400);
            } else {
              this.courseContentList = [];
              this.$nextTick(() => {
                this.resizeHeight();
              });
            }
          }, 200);
        } else {
          this.$message.error(res.data.msg);
        }
      });
    },
    //获取课程内容目录的活动内容list
    getCourseContentCatalogActivityList (id, type, secondItem, secondIndex, firstItem, firstIndex, callback) {
      this.$api.contentCatalogActivityHandle("/api/eduactivity/list", { courseThemeCatalogueContentId: id, activityClassify: type, }, "list").then((res) => {
        if (res.data.code === 0) {
          let info = {};
          if (secondItem) {
            info = { ...secondItem };
            info.activityList = res.data.data;
            this.$set(this.courseContentList[firstIndex].eduCourseThemeCatalogueContentTrees, secondIndex, info);
          } else {
            info = { ...firstItem };
            info.activityList = res.data.data;
            this.$set(this.courseContentList, firstIndex, info);
          }
          setTimeout(() => {
            this.resizeHeight();
            callback && callback()
          }, 300);
        } else {
          this.$message.error(res.data.msg);
          callback && callback()
        }
      });
    },
    //获取目标清单信息list
    getCourseContentCatalogTargetList () {
      this.$api.contentTargetHandle("/api/educoursethemecataloguetargetdescription/list", { courseThemeCatalogueId: this.courseContentCatalogId }, "list").then((res) => {
        if (res.data.code === 0) {
          this.courseContentTargetInfo = res.data.data;
        } else {
          this.$message.error(res.data.msg);
        }
      });
    },
    //切换目录
    handleTreeClick (info) {
      this.pageLoading = true;
      this.courseContentCatalogId = info.id;
      this.courseContentCatalogItemId = info.id;
      this.contentTitle = info.catalogueName;
      this.getCourseContentCatalogContentInfo();
      this.getCourseContentCatalogTargetList();
    },
    //目录内容设置
    handleCourseContentCatalog () {
      this.handleType = "add";
      this.courseSettingDialogVisible = true;
      this.$nextTick(() => {
        this.courseSettingType = "content";
      });
    },
    //刷新列表
    refreshPage (data) {
      this.pageLoading = true;
      // if (this.handleType == "add" && data) {
      //   this.targetSettingDialogVisible = true;
      //   this.$nextTick(() => {
      //     this.targetSettingDialogHandleType = "add";
      //   });
      // } else if (this.handleType == "add" && !data) {
      //   this.pageLoading = true;
      //   this.getCourseContentCatalogContentInfo();
      // }
      this.courseSettingDialogVisible = false;
      this.$nextTick(() => {
        this.courseSettingType = "";
        this.courseSettingData = {};
        // if (this.handleType == "add" && !data) {
        this.handleType == "";
        // }
        this.getCourseContentCatalogContentInfo();
      });
    },
    //获取指标设置操作
    getTargetInfo (data) {
      this.targetSettingDialogVisible = false;
      this.$nextTick(() => {
        this.targetSettingDialogHandleType = "";
        this.targetSettingData = {};
        this.handleType == "";
        this.pageLoading = true;
        this.getCourseContentCatalogContentInfo();
        this.getCourseContentCatalogTargetList();
      });
    },
    //内容操作
    handleContentItem (type, secondItem, secondIndex, firstItem, firstIndex) {
      this.secondInfo = secondItem;
      this.secondIndex = secondIndex;
      this.firstInfo = firstItem;
      this.firstIndex = firstIndex;
      switch (type) {
        case "addFile":
          this.uploadFileDialogVisible = true;
          this.$nextTick(() => {
            this.uploadFileHandleType = "add";
          });
          break;
        case "editItem":
          this.contentDialogVisible = true;
          this.$nextTick(() => {
            let contentName = secondItem ? secondItem.contentName : firstItem.contentName;
            let eduCourseThemeContentInfo = secondItem ? secondItem.eduCourseThemeContent : firstItem.eduCourseThemeContent;
            let content = "";
            if (eduCourseThemeContentInfo.length > 0) {
              content = eduCourseThemeContentInfo[0].content;
              this.contentData = {
                id: eduCourseThemeContentInfo[0].id,
                name: contentName,
                content,
              };
              this.contentDialogHandleType = "edit";
            } else {
              content = "";
              this.contentData = {
                id: "",
                name: contentName,
                content,
              };
              this.contentDialogHandleType = "add";
            }
            console.log(this.contentData);
            this.contentDialogType = "normal";
          });
          break;
        case "addVideoOrActivity":
          let type = secondItem ? secondItem.contentType : firstItem.contentType;
          if (type == "1") {
            this.uploadVideoDialogVisible = true;
            this.$nextTick(() => {
              this.uploadVideoHandleType = "add";
            });
          } else if (type == "2") {
            this.courseActivityVisible = true;
            this.$nextTick(() => {
              this.courseActivityTitle = "添加活动";
              this.courseActivityHandleType = "add";
            });
          }
          break;
        case "turnItem":
          let info = {};
          if (secondItem) {
            info = { ...secondItem };
            info.showFlag = !info.showFlag;
            this.$set(this.courseContentList[firstIndex].eduCourseThemeCatalogueContentTrees, secondIndex, info);
          } else {
            info = { ...firstItem };
            info.showFlag = !info.showFlag;
            this.$set(this.courseContentList, firstIndex, info);
          }
          this.$nextTick(() => {
            this.resizeHeight();
          });
          break;
      }
    },
    //预览文件
    fileView (fileInfo) {
      if (fileInfo.isDownload === '1') {
        let type = fileInfo.fileUrl.substr(fileInfo.fileUrl.lastIndexOf(".") + 1, fileInfo.fileUrl.length)
        if (this.fileTypeList.indexOf(type.toLowerCase()) != -1) {
          const a = document.createElement('a') // 创建一个a标签
          a.href = config.src // a标签的src属性赋值
          if (config.target) {
            a.target = config.target
          }
          a.download = config.fileName // a标签的download属性赋值
          document.body.appendChild(a) // 添加a标签到body下
          a.click() // 触发a标签点击事件
          document.body.removeChild(a) //  完成后删除a标签
        } else {
          window.location.href = fileInfo.fileUrl
        }
      } else {
        this.$message.warning("该附件不支持下载！")
      }
    },
    //删除文件
    delFile (fileInfo) {
      //"确定删除该附件, 是否继续?"
      const h = this.$createElement;
      this.$confirm(
        h("p", null, [
          h("span", { style: "color: rgba(0, 0, 0, 0.85);font-size: 16px;font-weight: 500;", }, "确定要删除该附件？"),
          h("div", { style: "color: rgba(0, 0, 0, 0.65);font-size: 14px;margin-top: 10px", }, "是否确定删除该附件，删除后，将无法恢复。"),
        ]), "提示", { confirmButtonText: "确定", cancelButtonText: "取消", iconClass: "el-icon-question", type: "warning", customClass: "warningMessage", }
      ).then(() => {
        this.pageLoading = true;
        this.$api.contentCatalogContentFileHandle("/api/educoursethemecataloguecontentfile/del", { id: fileInfo.id, delFlag: "1" }, "del").then((res) => {
          if (res.data.code === 0) {
            this.$message({
              type: "success", message: "删除成功!", duration: 2000, onClose: () => {
                this.pageLoading = false;
                this.getCourseContentCatalogContentInfo();
              },
            });
          } else {
            this.pageLoading = false;
            this.$message.error(res.data.msg);
          }
        });
      }).catch(() => { });
    },
    //视频预览
    videoView (videoInfo) {
      this.videoViewDialogVisible = true;
      this.$nextTick(() => {
        let type = videoInfo.videoUrl.substr(videoInfo.videoUrl.lastIndexOf(".") + 1, videoInfo.videoUrl.length);
        console.log(type);
        this.videoViewType = type == "mp4" || type == "avi" ? "video" : type == "mp3" ? "audio" : "";
        this.videoViewUrl = videoInfo.videoUrl;
      });
    },
    //视频预览关闭
    videoViewClose () {
      this.videoViewDialogVisible = false;
      this.$nextTick(() => {
        this.videoViewType = "";
        this.videoViewUrl = "";
      });
    },
    //编辑视频
    editVideo (videoInfo, videoIndex, secondItem, secondIndex, firstItem, firstIndex) {
      this.secondInfo = secondItem;
      this.secondIndex = secondIndex;
      this.firstInfo = firstItem;
      this.firstIndex = firstIndex;
      this.uploadVideoDialogVisible = true;
      this.$nextTick(() => {
        this.uploadVideoHandleType = "edit";
        this.videoData = {
          id: videoInfo.id,
          videoName: videoInfo.videoName || "",
          imageUrl: videoInfo.fontImg || "",
          videoUrl: videoInfo.videoUrl,
          remark: videoInfo.intro,
        };
        console.log(this.videoData);
      });
    },
    //删除视频
    delVideo (videoInfo) {
      //"确定删除该视频, 是否继续?"
      const h = this.$createElement;
      this.$confirm(
        h("p", null, [
          h("span", { style: "color: rgba(0, 0, 0, 0.85);font-size: 16px;font-weight: 500;", }, "确定要删除该视频？"),
          h("div", { style: "color: rgba(0, 0, 0, 0.65);font-size: 14px;margin-top: 10px", }, "是否确定删除该视频，删除后，将无法恢复。"),
        ]), "提示", { confirmButtonText: "确定", cancelButtonText: "取消", iconClass: "el-icon-question", type: "warning", customClass: "warningMessage", }
      ).then(() => {
        this.pageLoading = true;
        this.$api.contentCatalogVideoHandle("/api/educoursethemecataloguecontentvideo/del", { id: videoInfo.id, delFlag: "1" }, "del").then((res) => {
          if (res.data.code === 0) {
            this.$message({
              type: "success", message: "删除成功!", duration: 2000, onClose: () => {
                this.pageLoading = false;
                this.getCourseContentCatalogContentInfo();
              },
            });
          } else {
            this.pageLoading = false;
            this.$message.error(res.data.msg);
          }
        });
      }).catch(() => { });
    },
    //目标清单操作
    handleTaggetItem (type, row) {
      if (type == "suggestView") {
        this.suggestsViewDialogVisible = true;
        this.$nextTick(() => {
          this.courseContentCatalogTargetId = row.targetCatalogueId;
        });
      } else if (type == "practiceView") {
        this.practicesViewDialogVisible = true;
        this.$nextTick(() => {
          this.courseContentCatalogTargetId = row.targetCatalogueId;
          this.courseContentCatalogTargetFlag = row.flag;
          this.courseContentCatalogTargetTypeValue = row.typeValue;
        });
      }
    },
    //教学建议查看关闭
    suggestsDialogClose () {
      this.suggestsViewDialogVisible = false;
      this.$nextTick(() => {
        this.courseContentCatalogTargetId = null;
      });
    },
    //教学实践查看关闭
    practicesDialogClose () {
      this.practicesViewDialogVisible = false;
      this.$nextTick(() => {
        this.courseContentCatalogTargetId = null;
        this.courseContentCatalogTargetFlag = "";
        this.courseContentCatalogTargetTypeValue = "";
      });
    },
    //活动切换操作
    handleRadioChange (val, secondItem, secondIndex, firstItem, firstIndex) {
      let info = null;
      if (secondItem) {
        info = { ...secondItem };
        info.activityTypeRadio = val;
        this.$set(this.courseContentList[firstIndex].eduCourseThemeCatalogueContentTrees, secondIndex, info);
        this.getCourseContentCatalogActivityList(secondItem.id, val, info, secondIndex, firstItem, firstIndex);
      } else {
        info = { ...firstItem };
        info.activityTypeRadio = val;
        this.$set(this.courseContentList, firstIndex, info);
        this.getCourseContentCatalogActivityList(firstItem.id, val, secondItem, secondIndex, info, firstIndex);
      }
    },
    //活动内容操作
    handleActivityItem (type, activityInfo, secondItem, secondIndex, firstItem, firstIndex) {
      this.secondInfo = secondItem;
      this.secondIndex = secondIndex;
      this.firstInfo = firstItem;
      this.firstIndex = firstIndex;
      if (type == "edit") {
        this.courseActivityVisible = true;
        this.$nextTick(() => {
          this.courseActivityTitle = "编辑活动";
          this.courseActivityHandleType = "edit";
          this.courseActivityForm.id = activityInfo.id;
          this.courseActivityForm.activityClassify = activityInfo.activityClassify;
          this.courseActivityForm.activityName = activityInfo.activityName;
          this.activityContent = activityInfo.activityContent;
          this.editorContent = activityInfo.activityContent
        });
      } else if (type == "del") {
        //"确定删除该活动, 是否继续?"
        const h = this.$createElement;
        this.$confirm(
          h("p", null, [
            h("span", { style: "color: rgba(0, 0, 0, 0.85);font-size: 16px;font-weight: 500;", }, "确定要删除该活动？"),
            h("div", { style: "color: rgba(0, 0, 0, 0.65);font-size: 14px;margin-top: 10px", }, "是否确定删除该活动，删除后，将无法恢复。"),
          ]), "提示", { confirmButtonText: "确定", cancelButtonText: "取消", iconClass: "el-icon-question", type: "warning", customClass: "warningMessage", }
        ).then(() => {
          this.$api.contentCatalogActivityHandle("/api/eduactivity/del", { id: activityInfo.id, delFlag: "1" }, "del").then((res) => {
            if (res.data.code === 0) {
              this.$message({
                type: "success", message: "删除成功!", duration: 2000, onClose: () => {
                  // let info = null;
                  // if (this.secondInfo) {
                  //   info = { ...this.secondInfo };
                  //   info.activityTypeRadio = this.activityTypeOptions[0].value;
                  //   this.$set(this.courseContentList[this.firstIndex].eduCourseThemeCatalogueContentTrees, this.secondIndex, info);
                  //   this.$nextTick(() => {
                  //     this.getCourseContentCatalogActivityList(info.id, this.activityTypeOptions[0].value, info, this.secondIndex, this.firstInfo, this.firstIndex);
                  //   });
                  // } else {
                  //   info = { ...this.firstInfo };
                  //   info.activityTypeRadio = this.activityTypeOptions[0].value;
                  //   this.$set(this.courseContentList, this.firstIndex, info);
                  //   this.$nextTick(() => {
                  //     this.getCourseContentCatalogActivityList(info.id, this.activityTypeOptions[0].value, this.secondInfo, this.secondIndex, info, this.firstIndex);
                  //   });
                  // }
                  this.pageLoading = true
                  this.getCourseContentCatalogContentInfo()
                },
              });
            } else {
              this.$message.error(res.data.msg);
            }
          });
        }).catch(() => { });
      }
    },
    //富文本上传图片成功方法
    handleSuccess (res) {
      // 获取富文本组件实例
      let quill = this.$refs.QuillEditor.quill;
      // 如果上传成功
      if (res) {
        // 获取光标所在位置
        let length = quill.getSelection().index;
        // 插入图片，res为服务器返回的图片链接地址
        quill.insertEmbed(length, "image", res);
        // 调整光标到最后
        quill.setSelection(length + 1);
      } else {
        // 提示信息，需引入Message
        Message.error("图片插入失败");
      }
    },
    //上传封面成功事件
    handleAvatarSuccess (res, file) {
      console.log(res);
      this.handleSuccess(res);
    },
    //上传封面之前事件
    beforeAvatarUpload (file) {
      const isJPG = this.fileTypeList.indexOf(file.name.substr(file.name.lastIndexOf(".") + 1, file.name.length).toLowerCase()) !== -1;
      const isLt2M = file.size / 1024 / 1024 < 10;

      if (!isJPG) {
        this.$message.error("上传图片只能是 png, jpg, jpeg 格式!");
      }
      if (!isLt2M) {
        this.$message.error("上传图片大小不能超过 10MB!");
      }
      return isJPG && isLt2M; //
    },
    //获取图文值
    getContent (data) {
      this.activityContent = data.content
    },
    //弹框关闭
    handleCourseActivityClose () {
      this.courseActivityVisible = false;
      this.$nextTick(() => {
        this.courseActivityForm = {
          activityClassify: "",
          activityName: "",
          // activityContent: "",
        };
        this.editorContent = ""
        this.activityContent = ""
        this.courseActivityTitle = "";
        this.courseActivityHandleType = "";
        this.firstInfo = null;
        this.firstIndex = null;
        this.secondInfo = null;
        this.secondIndex = null;
        this.$refs.courseActivityForm.clearValidate();
      });
    },
    //弹窗保存
    handleCourseActivitySubmit () {
      this.$refs.courseActivityForm.validate((valid) => {
        if (valid) {
          this.buttonloading = true;
          let params = { ...this.courseActivityForm };
          params.activityContent = this.activityContent
          let url = "";
          if (this.courseActivityHandleType == "add") {
            params.courseThemeCatalogueContentId = this.secondInfo ? this.secondInfo.id : this.firstInfo.id;
            url = "/api/eduactivity";
          } else {
            url = "/api/eduactivity";
          }
          this.$api.contentCatalogActivityHandle(url, params, this.courseActivityHandleType).then((res) => {
            if (res.data.code === 0) {
              this.$message({
                message: "保存成功", type: "success", duration: 2000, onClose: () => {
                  this.buttonloading = false;
                  // let info = null;
                  // if (this.secondInfo) {
                  //   info = { ...this.secondInfo };
                  //   info.activityTypeRadio = this.activityTypeOptions[0].value;
                  //   this.$set(this.courseContentList[this.firstIndex].eduCourseThemeCatalogueContentTrees, this.secondIndex, info);
                  //   this.$nextTick(() => {
                  //     this.getCourseContentCatalogActivityList(info.id, this.activityTypeOptions[0].value, info, this.secondIndex, this.firstInfo, this.firstIndex,() => {
                  //       this.handleCourseActivityClose();
                  //     });
                  //   });
                  // } else {
                  //   info = { ...this.firstInfo };
                  //   info.activityTypeRadio = this.activityTypeOptions[0].value;
                  //   this.$set(this.courseContentList, this.firstIndex, info);
                  //   this.$nextTick(() => {
                  //     this.getCourseContentCatalogActivityList(info.id, this.activityTypeOptions[0].value, this.secondInfo, this.secondIndex, info, this.firstIndex,() => {
                  //       this.handleCourseActivityClose();
                  //     });
                  //   });
                  // }
                  this.handleCourseActivityClose();
                  this.pageLoading = true
                  this.getCourseContentCatalogContentInfo()
                },
              });
            } else {
              this.buttonloading = false;
              this.$message.error(res.data.msg);
            }
          });
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    //编辑课程内容
    courseEdit () {
      this.courseContentVisible = true;
      this.$nextTick(() => {
        this.courseContentTitle = "编辑";
        // this.courseContentId = this.courseContentInfo.id
        this.courseContentForm.courseThemeName = this.courseContentInfo.courseThemeName
        this.courseContentForm.coverImg = this.courseContentInfo.coverImg
        this.courseContentForm.description = this.courseContentInfo.description
      });
    },
    //上传封面成功事件
    handleContentAvatarSuccess (res, file) {
      this.courseContentForm.coverImg = res;
      this.pageLoading = false
    },
    //上传封面之前事件
    beforeContentAvatarUpload (file) {
      const isJPG = this.fileTypeList.indexOf(file.name.substr(file.name.lastIndexOf(".") + 1, file.name.length).toLowerCase()) !== -1;
      const isLt2M = file.size / 1024 / 1024 < 10;

      if (!isJPG) {
        this.$message.error("上传图片只能是 png, jpg, jpeg 格式!");
      }
      if (!isLt2M) {
        this.$message.error("上传图片大小不能超过 10MB!");
      }
      if (isJPG && isLt2M) {
        this.pageLoading = true
      }
      return isJPG && isLt2M; //&& isLt2M
    },
    //删除课程内容封面
    delCourseContentImg () {//"确定删除该封面图片, 是否继续?"
      const h = this.$createElement;
      this.$confirm(
        h('p', null, [
          h('span', { style: 'color: rgba(0, 0, 0, 0.85);font-size: 16px;font-weight: 500;' }, '确定要删除该封面图片？'),
          h('div', { style: 'color: rgba(0, 0, 0, 0.65);font-size: 14px;margin-top: 10px' }, '是否确定删除该封面图片，删除后，将无法恢复。')
        ]), "提示", {
        confirmButtonText: "确定", cancelButtonText: "取消", iconClass: "el-icon-question", type: "warning", customClass: "warningMessage"
      }).then(() => {
        this.courseContentForm.coverImg = "";
      }).catch(() => { });
    },
    //弹框关闭
    handleClose () {
      this.courseContentVisible = false;
      this.$nextTick(() => {
        this.courseContentForm = {
          courseThemeName: "",
          coverImg: "",
          description: "",
        };
        // this.courseContentId = "";
        this.courseContentTitle = "";
        this.$refs.courseContentForm.clearValidate();
      });
    },
    //弹窗完成
    handleSubmit () {
      this.$refs.courseContentForm.validate((valid) => {
        if (valid) {
          this.buttonloading = true;
          let params = { ...this.courseContentForm };
          params.courseId = this.courseId;
          let url = "";
          if (this.courseContentId == "") {
            url = "/api/educoursetheme";
          } else {
            params.id = this.courseContentId;
            url = "/api/educoursetheme";
          }
          this.$api.courseContentHandle(url, params, this.courseContentId ? "edit" : "add").then((res) => {
            if (res.data.code === 0) {
              this.$message({
                message: "保存成功", type: "success", duration: 2000, onClose: () => {
                  this.buttonloading = false;
                  this.handleClose();
                  this.pageLoading = true;
                  this.getData(() => {
                    this.pageLoading = false;
                  });
                },
              });
            } else {
              this.$message.error(res.data.msg);
            }
          });
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    //发布课程内容
    coursePush () {
      this.pageLoading = true;
      this.$api.courseContentHandle("/api/educoursetheme", { id: this.courseContentId, courseThemeName: this.courseContentInfo.courseThemeName, status: 1 }, "edit").then((res) => {
        if (res.data.code === 0) {
          this.$message({
            message: "发布成功", type: "success", duration: 2000, onClose: () => {
              this.getData(() => {
                this.pageLoading = false;
              });
            },
          });
        } else {
          this.$message.error(res.data.msg);
        }
      });
    },
    //获取内容编辑操作
    getContentInfo (data) {
      let params = {
        courseThemeCatalogueContentId: this.secondInfo ? this.secondInfo.id : this.firstInfo.id,
        contentName: data.formData.name,
        content: data.formData.content,
        id: data.formData.id,
      };
      let url = "";
      if (this.contentDialogHandleType == "add") {
        url = "/api/educoursethemecontent";
      } else if (this.contentDialogHandleType == "edit") {
        url = "/api/educoursethemecontent";
      }
      this.$api.contentCatalogContentHandle(url, params, this.contentDialogHandleType).then((res) => {
        if (res.data.code === 0) {
          this.$message({
            type: "success", message: this.contentDialogType == "add" ? "内容添加成功" : "内容修改成功", duration: 2000, onClose: () => {
              this.contentDialogVisible = false;
              this.$nextTick(() => {
                this.contentData = {};
                this.contentDialogType = "";
                this.contentDialogHandleType = "";
                this.firstInfo = null;
                this.firstIndex = null;
                this.secondInfo = null;
                this.secondIndex = null;
                this.getCourseContentCatalogContentInfo();
              });
            },
          });
        } else {
          this.$message.error(res.data.msg);
          this.$refs.contentComponent.buttonloading = false;
        }
      });
    },
    //获取视频编辑操作
    getVideoInfo (data) {
      let params = {
        videoName: data.formData.videoName,
        fontImg: data.formData.imageUrl,
        videoUrl: data.formData.videoUrl,
        intro: data.formData.remark,
      };
      let url = "";
      if (this.uploadVideoHandleType == "add") {
        params.courseThemeCatalogueContentId = this.secondInfo ? this.secondInfo.id : this.firstInfo.id;
        url = "/api/educoursethemecataloguecontentvideo";
      } else if (this.uploadVideoHandleType == "edit") {
        url = "/api/educoursethemecataloguecontentvideo";
        params.id = data.formData.id;
      }
      this.$api.contentCatalogVideoHandle(url, params, this.uploadVideoHandleType).then((res) => {
        if (res.data.code === 0) {
          this.$message({
            type: "success", message: this.uploadVideoHandleType == "add" ? "视频添加成功" : "视频修改成功", duration: 2000, onClose: () => {
              this.uploadVideoDialogVisible = false;
              this.$nextTick(() => {
                this.videoData = {};
                this.uploadVideoHandleType = "";
                this.firstInfo = null;
                this.firstIndex = null;
                this.secondInfo = null;
                this.secondIndex = null;
                this.getCourseContentCatalogContentInfo();
              });
            },
          });
        } else {
          this.$message.error(res.data.msg);
          this.$refs.uploadVideo.buttonloading = false;
        }
      });
    },
    //获取文件编辑操作
    getFileInfo (data) {
      const { url, fileId, fileVersionId, name } = data.fileList[0]
      let params = {
        courseThemeCatalogueContentId: this.secondInfo ? this.secondInfo.id : this.firstInfo.id,
        originalName: name,
        fileUrl: url,
        fileVersionId,
        fileId,
        remark: data.formData.remark,
        isDownload: data.formData.isDownload,
      };
      let urlStr = "";
      if (this.uploadFileHandleType == "add") {
        urlStr = "/api/educoursethemecataloguecontentfile";
      } else if (this.uploadFileHandleType == "edit") {
        urlStr = "/api/educoursethemecataloguecontentfile";
        // params.id = ""
      }
      this.$api.contentCatalogContentFileHandle(urlStr, params, this.uploadFileHandleType).then((res) => {
        if (res.data.code === 0) {
          this.$message({
            type: "success", message: this.uploadFileHandleType == "add" ? "附件添加成功" : "附件修改成功", duration: 2000, onClose: () => {
              this.uploadFileDialogVisible = false;
              this.$nextTick(() => {
                this.uploadFileHandleType = "";
                this.firstInfo = null;
                this.firstIndex = null;
                this.secondInfo = null;
                this.secondIndex = null;
                this.getCourseContentCatalogContentInfo();
              });
            },
          });
        } else {
          this.$message.error(res.data.msg);
          this.$refs.uploadFile.buttonloading = false;
        }
      });
    },
  },
  beforeDestroy () {
    window.onresize = null
  }
};
</script>
<style lang="scss" scoped>
.content-body {
  width: 100%;
  display: flex;
  align-items: flex-start;
  background-color: #ffffff;
  min-height: 500px;
  .body-left {
    width: 20%;
    min-height: 500px;
    background-color: #ffffff;
    box-shadow: 0px 3px 17px rgba(0, 0, 0, 0.05);
    .el-menu-item:focus,
    .el-menu-item:hover {
      background-color: transparent !important;
    }
    .el-submenu .el-menu-item {
      height: auto;
      line-height: normal;
      padding: 0 !important;
    }
    .tree-list {
      width: 100%;
      .tree-outside {
        width: 100%;
        position: relative;
      }
      .tree-item {
        width: 100%;
      }
      .tree-title {
        width: calc(100% - 58px);
        padding: 20px 18px 20px 40px;
        font-size: 16px;
        font-weight: 400;
        color: rgba(0, 0, 0, 0.75);
        cursor: pointer;
        position: relative;
        white-space: normal;
        word-break: break-all;
        &.active {
          background-color: rgba(89, 126, 247, 0.05);
          border-left: 4px solid #597ef7;
          width: calc(100% - 62px);
          border-radius: 5px;
          &.parentShow {
            width: calc(100% - 106px);
          }
        }
        &.parentShow {
          width: calc(100% - 102px);
          padding: 20px 62px 20px 40px;
        }
        .parent-show {
          position: absolute;
          top: 50%;
          right: 10px;
          transform: translateY(-50%);
          width: 52px;
          height: 28px;
          color: #ffffff;
          font-weight: 400;
          font-size: 14px;
          background-color: #597ef7;
        }
      }
      .sub-tree-list {
        width: 100%;
        .tree-title {
          width: calc(100% - 78px);
          padding: 20px 18px 20px 60px;
          &.active {
            width: calc(100% - 82px);
            &.parentShow {
              width: calc(100% - 126px);
            }
          }
          &.parentShow {
            width: calc(100% - 122px);
            padding: 20px 62px 20px 60px;
          }
        }
      }
    }
    .add-catalog-button-div {
      width: 100%;
      margin-top: 20px;
      text-align: center;
      .add-catalog-button {
        width: 160px;
        height: 40px;
        border: 2px solid #597ef7;
        &.el-button--primary {
          background-color: #ffffff;
          &:hover {
            background-color: #597ef7;
            color: #ffffff;
          }
          &:focus {
            background-color: #597ef7;
            color: #ffffff;
          }
        }
      }
    }
  }
  .body-right {
    width: 80%;
    .content-title {
      width: calc(100% - 28px);
      margin-left: 28px;
      padding: 35px 0 17px 0;
      font-size: 26px;
      font-weight: bold;
      color: #000000;
      display: flex;
      justify-content: space-between;
      align-items: center;
      img {
        width: 26px;
        height: 26px;
        margin: 0 28px;
      }
    }
  }
}
.poper-div {
  position: absolute;
  bottom: -50px;
  left: 20px;
  width: 240px;
  height: 50px;
  background-color: #ffffff;
  box-shadow: 0 0 13px rgba(0, 0, 0, 0.15);
  z-index: 2;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 10px;
  .popor-arrow {
    position: absolute;
    top: -10px;
    left: 40px;
    width: 0;
    height: 0;
    border-top: 5px solid transparent;
    border-left: 5px solid transparent;
    border-right: 5px solid transparent;
    border-bottom: 5px solid #ffffff;
  }
  .poper-item {
    font-size: 14px;
    color: rgba(0, 0, 0, 0.65);
    cursor: pointer;
    &:hover {
      color: #597ef7;
    }
  }
}
//tab样式
/deep/.el-tabs__nav-wrap {
  padding: 0 20px;
  height: 60px;
}

/deep/.el-tabs__nav {
  font-size: 20px;
  height: 60px;
  .el-tabs__item {
    height: 60px;
    line-height: 60px;
    font-size: 20px;
  }
}

/deep/.el-dialog__body {
  color: unset;
}
</style>