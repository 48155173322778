<template>
  <el-dialog class="practices-view-dialog" title="教学实践详情" :visible.sync="visible" :practicesViewDialogVisible="practicesViewDialogVisible" :append-to-body="true" :destroy-on-close="true" @before-close="handleClose" @close="handleClose" width="50%">
    <el-form ref="practicesViewForm">
      <el-row>
        <el-col :span="24">
          <div class="view-title" v-show="targetCode">{{ targetCode }}：{{ targetName }}</div>
        </el-col>
        <el-col :span="24">
          <el-form-item>
            <el-tabs v-model="activeTab" @tab-click="handeTabClick" class="tab-info" v-if="practicesList.length > 0">
              <el-tab-pane :label="item.tagName" :name="item.id+''" v-for="item in practicesList" :key="item.id">
                <div class="panel-data" style="width: auto;min-height: 0;">
                  <div class="second-panel" style="width: auto" v-for="info in item.practices" :key="info.id">
                    <div class="panel-header">
                      <div class="title">{{ info.practiceTitle }}</div>
                      <div class="button"></div>
                    </div>
                    <div class="panel-content">
                      <div class="content-info" v-if="info.practiceContent">
                        <!-- <div class="ql-container ql-snow">
                          <div class="ql-editor" v-html="info.practiceContent"></div>
                        </div> -->
                        <div class="w-e-text-container">
                          <div class="w-e-text" v-html="info.practiceContent"></div>
                        </div>
                      </div>
                      <div class="file-info" v-if="info.files">
                        <div class="file-item" v-for="fileInfo in info.files" :key="fileInfo.id">
                          <div class="content">
                            <img class="icon" :src="fileIconPDF" alt="" v-if="fileInfo.iconType == 'pdf'">
                            <img class="icon" :src="fileIconWord" alt="" v-if="fileInfo.iconType == 'doc'">
                            <img class="icon" :src="fileIconExcel" alt="" v-if="fileInfo.iconType == 'xls'">
                            <img class="icon" :src="fileIconPPT" alt="" v-if="fileInfo.iconType == 'ppt'"/>
                            <div class="message">
                              <div class="title">{{ fileInfo.originalName }}</div>
                              <el-tooltip class="item" effect="dark" :content="fileInfo.remark" placement="top-start">
                                <div class="ltitle">{{ fileInfo.remark }}</div>
                              </el-tooltip>
                            </div>
                          </div>
                          <div class="bottom">
                            <img :src="fileViewIcon" alt="" @click="fileView(fileInfo)" style="margin-right: 15%"/>
                            <!-- <el-divider direction="vertical"></el-divider>
                            <img :src="delIcon" alt="" @click="delFile(fileInfo)" style="margin: 0 15%"/> -->
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </el-tab-pane>
            </el-tabs>
          </el-form-item>
        </el-col>
      </el-row>
    </el-form>
    <div slot="footer" class="dialog-footer">
      <el-button @click="handleClose">取 消</el-button>
    </div>
  </el-dialog>
</template>
<script>
import emptyImg from "@/assets/img/empty-img.png";
import fileViewIcon from "@/assets/img/file-view-icon.png";
import delIcon from "@/assets/img/del-icon.png";
import fileIconPDF from "@/assets/img/file-icon-PDF.png";
import fileIconWord from "@/assets/img/file-icon-word.png";
import fileIconExcel from "@/assets/img/file-icon-excel.png";
import fileIconPPT from "@/assets/img/file-icon-ppt.png";
export default {
  name: "SuggestsViewDialog",
  props: {
    practicesViewDialogVisible: {
      type: Boolean,
      default: false,
    },
    courseContentCatalogTargetId: {
      type: Number,
      default: null,
    },
    courseContentCatalogTargetFlag: {
      type: String,
      default: "",
    },
    courseContentCatalogTargetTypeValue: {
      type: String,
      default: "",
    },
  },
  watch: {
    practicesViewDialogVisible() {
      this.visible = this.practicesViewDialogVisible;
    },
    courseContentCatalogTargetId(val) {
      if (val && this.practicesViewDialogVisible) {
        this.getTargetInfo()
        this.getTargetPracticesList();
      }
    },
  },
  data() {
    return {
      visible: false, //弹框显隐
      activeTab: "", //标签绑定值
      practicesList: [], //实践列表
      targetCode: "", //目标编号
      targetName: "", //目标名称

      emptyImg, //空数据图片
      fileViewIcon, //文件预览icon
      delIcon, //删除icon
      fileIconPDF, //pdfIcon
      fileIconWord, //wordIcon
      fileIconExcel, //excelIcon
      fileIconPPT,//PPTIcon
    };
  },
  methods: {
    //获取目标信息
    getTargetInfo() {
      this.$api.getTargetInfoDetailByCatalogueId({ id: this.courseContentCatalogTargetId, }).then((res) => {
        this.tableLoading = false;
        if (res.data.code === 0) {
          this.targetCode = res.data.data.info.infoCode;
          this.targetName = res.data.data.info.infoName;
        } else {
          this.$message.error(res.data.msg);
        }
      });
    },
    //获取目标实践list
    getTargetPracticesList() {
      this.$api.getTargetInfoByDict({ id: this.courseContentCatalogTargetId, flag: this.courseContentCatalogTargetFlag, typeValue: this.courseContentCatalogTargetTypeValue}).then((res) => {
        if (res.data.code === 0) {
          res.data.data.tags.map((item,i) => {
            if(i == 0){
              this.activeTab = item.id+""
              item.practices = JSON.parse(JSON.stringify(res.data.data.practices))
            } else {
              item.practices = []
            }
          });
          this.practicesList = res.data.data.tags;
        } else {
          this.$message.error(res.data.msg);
        }
      });
    },
    //预览文件
    fileView(fileInfo) {
      if(fileInfo.isDownload === '1') {
        window.location.href = fileInfo.fileUrl
      } else {
        this.$message.warning("该附件不支持下载！")
      }
    },
    //切换标签
    handeTabClick(val){
      let index = this.practicesList.findIndex((item) => { return item.id == this.activeTab })
      this.$nextTick(() => {
        this.getTargetPractices(index)
      })
    },
    //获取标签下的实践详情
    getTargetPractices(index){
      this.$api.getTargetInfoByPracticeTag({ id: this.courseContentCatalogTargetId, flag: this.courseContentCatalogTargetFlag, typeValue: this.courseContentCatalogTargetTypeValue, tagId: this.activeTab }).then((res) => {
          if (res.data.code === 0) {
            let info = this.practicesList[index]
            res.data.data.map((item) => {
            if(item.files && item.files.length > 0){
              item.files.map((info) => {
                let type = info.fileUrl.substr(info.fileUrl.lastIndexOf("."), info.fileUrl.length)
                if(type.toLowerCase().indexOf("pdf") != -1){
                  info.iconType = 'pdf'
                }
                if(type.toLowerCase().indexOf("doc") != -1){
                  info.iconType = 'doc'
                }
                if(type.toLowerCase().indexOf("xls") != -1){
                  info.iconType = 'xls'
                }
                if (type.toLowerCase().indexOf("ppt") != -1) {
                  info.iconType = "ppt";
                }
              })
            }
          })
          info.practices = JSON.parse(JSON.stringify(res.data.data))
          this.$set(this.practicesList,info,index)
        } else {
          this.$message.error(res.data.msg);
        }
      });
    },
    //目标建议弹窗关闭
    handleClose() {
      this.targetCode = "";
      this.targetName = "";
      this.practicesList = [];
      this.activeTab = "";
      this.$emit("closeDialog", false);
    },
  },
};
</script>